import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import Loader from '../Loader'
import request from 'superagent'
import { fileToBase64 } from '../../utils/file'
// @ts-ignore
import Rodal from 'rodal'
// include styles
import 'rodal/lib/rodal.css'
import './index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faMobileAlt } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'
import client from 'src/services/feathers'

export interface DocumentI {
  id?: number
  name?: string
  status: number
  statusName?: string
  document_type: {
    comment: string
    id?: string
    mustBeSigned?: boolean
    mustBeUploaded?: boolean
  }
  datas?: {
    youSign?: {
      urlProcecure?: string
    }
    ssp?: {
      signatureUrl?: string
    }
  }
  documentTypeId?: string
}

export interface DocumentPropsI {
  subscriptionToken: string
  subscriptionId: string
  data: DocumentI
}

export enum ESecurityMethods {
  SMS = "otp_sms",
  EMAIL = "otp_email"
}

const DocumentCompo: React.FC<DocumentPropsI> = ({ subscriptionToken, subscriptionId, data }) => {
  const contractService = client.service('contract')
  const document = data

  const [isPending, setIsPending] = useState(false)
  const [isUploded, setIsUploaded] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const [signUrl, setSignUrl] = useState<string>()
  const [isSecurityContractModalVisible, setSecurityContractModalVisible] = useState(false)
  const [securityMethod, setSecurityMethod] = useState<ESecurityMethods>()
  const [contractGenerationLoading, setContractGenerationLoading] = useState(false)

  const toggle = () => setIsVisible(!isVisible)
  const toggleSecurityContractModal = () => setSecurityContractModalVisible(!isSecurityContractModalVisible)

  /**
   * UPLOAD
   */
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png, application/pdf',
    maxFiles: 2,
    onDrop: async (files) => {
      setIsPending(true)
      setHasError(false)
      setIsUploaded(true)

      const base54 = await fileToBase64(files[0])

      try {
        await request
          .put(`${process.env.REACT_APP_API_URL}/upload/${document.id}`)
          .send({
            file: base54,
            name: files[0].name,
          })
          .set('subscription_token', subscriptionToken)
          .set('subscription_id', subscriptionId)
      } catch (error: any) {
        setHasError(true)
      } finally {
        setIsPending(false)
      }
    },
  })

  const goToSign = async (document: DocumentI) => {
    if (document.status === 100 && document.datas) {
      if (document.document_type.id === 'SEPA' && document.datas.ssp && document.datas.ssp.signatureUrl) {
        window.location.href = document.datas.ssp.signatureUrl
      } else if (document.datas.youSign) {
        setSignUrl(document.datas.youSign.urlProcecure)

        toggle()
      }
    }

    if (document.status === 0 && document.datas) setSecurityContractModalVisible(true)
  }

  const generateContract = async () => {
    if (!securityMethod) return
    setContractGenerationLoading(true)

    try {
      await contractService.create({
        documentId: document.id,
        otpMethod: securityMethod
      })
    } catch (error: any) {
      console.error(error)
      toast.error("Une erreur est survenue lors de la génération du contrat")
    } finally {
      setSecurityMethod(undefined)
      setContractGenerationLoading(false)
      toggleSecurityContractModal()
    }
  }

  return (
    <>
      {document.document_type.mustBeUploaded && (
        <div
          style={{
            border: '1px solid #d22630',
            textAlign: 'center',
            position: 'relative',
            cursor: 'pointer',
            width: 300,
            height: 190,
            padding: 10,
            margin: 10,
          }}
          {...getRootProps({ className: 'dropzone' })}
        >
          {isPending && <Loader />}
          <div style={{ fontSize: 18, marginBottom: 20 }}>{document.name}</div>
          {document.document_type.comment && <p>{document.document_type.comment}</p>}
          <div style={{ marginBottom: 20 }}>{document.statusName}</div>

          {(hasError || !isUploded) && <input {...getInputProps()} />}

          {isUploded && !hasError && !isPending && (
            <p style={{ color: 'rgb(38, 210, 60)' }}>Merci, votre document est déposé avec succès</p>
          )}
          {isUploded && hasError && !isPending && (
            <p style={{ color: '#d22630' }}>
              Une erreur est survenue, merci de déposer un document au format jpg, png ou pdf de moins de 10Mo
            </p>
          )}
          {isPending && <p>Envoie en cours ...</p>}
          {!isUploded && !isPending && <p>Cliquez pour déposer votre document</p>}
        </div>
      )}
      {document.document_type.mustBeSigned && (
        <>
          <div
            className="sign-frame"
            style={{
              border: '1px solid #d22630',
              textAlign: 'center',
              position: 'relative',
              width: 300,
              height: 190,
              cursor: [100].includes(document.status) ? 'pointer' : 'default',
              padding: 10,
              margin: 10,
            }}
            onClick={() => goToSign(document)}
          >
            <div style={{ fontSize: 18, marginBottom: 20 }}>{document.name}</div>
            <div style={{ marginBottom: 20 }}>{document.statusName}</div>

            {[210, 240].includes(document.status) && (
              <p style={{ color: 'rgb(38, 210, 60)' }}>Le document a été signé, merci. </p>
            )}
            {[100].includes(document.status) && <p>Cliquez ici pour finaliser votre ouverture de compte</p>}
            {[0].includes(document.status) && document.documentTypeId === "CONTRACT" && <p>Clique ici pour démarrer la génération du contrat</p>}
          </div>

          <Rodal
            id="confirm-security-contract-modal"
            visible={isSecurityContractModalVisible}
            onClose={toggleSecurityContractModal}
            customStyles={{
              maxWidth: '50rem',
              display: 'flex',
              flexDirection: 'column',
              padding: '0rem',
            }}
          >
            <div className='securityTitle'>
              <h2>Méthode de sécurité</h2>
              <p>Choisissez votre méthode de sécurité pour signer votre contrat</p>
            </div>
            <div className='securityButtonChoices'>
              <div className={`securityChoice ${securityMethod === ESecurityMethods.SMS ? 'securityChoiceSelected' : 'securityChoiceUnselected'}`} onClick={() => setSecurityMethod(ESecurityMethods.SMS)}>
                <FontAwesomeIcon icon={faMobileAlt} style={{ fontSize: '1.5rem' }} />
                <p className='securityChoiceText'>SMS</p>
                {securityMethod === ESecurityMethods.SMS ?
                  <span className="securitySelectedDot"></span> :
                  <span className='securityUnselectedDot'></span>
                }
              </div>
              <div className={`securityChoice ${securityMethod === ESecurityMethods.EMAIL ? 'securityChoiceSelected' : 'securityChoiceUnselected'}`} onClick={() => setSecurityMethod(ESecurityMethods.EMAIL)}>
                <FontAwesomeIcon icon={faEnvelope} style={{ fontSize: '1.5rem' }} />
                <p className='securityChoiceText'>EMAIL</p>
                {securityMethod === ESecurityMethods.EMAIL ?
                  <span className="securitySelectedDot"></span> :
                  <span className='securityUnselectedDot'></span>
                }
              </div>
            </div>

            {contractGenerationLoading && <Loader />}
            <button className='securityConfirm' disabled={!securityMethod} onClick={generateContract}>
              Confirmer
            </button>
          </Rodal>
          <Rodal
            id="signature-modal"
            visible={isVisible}
            onClose={toggle}
            customStyles={{
              width: 'auto',
              height: '100%',
              minHeight: '100%',
              maxHeight: '100%',
              margin: '0',
              minWidth: 'auto',
              maxWidth: 'auto',
            }}
          >
            <iframe
              style={{
                width: '90%',
                height: '90%',
                minWidth: 'auto',
                maxWidth: 'auto',
              }}
              src={signUrl}
              title="signature frame"
            ></iframe>
          </Rodal>
        </>
      )}
    </>
  )
}

export default DocumentCompo
